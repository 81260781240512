<template>
   <div class="row">
      <div class="col-12 text-center">
         <div class="bar-container ">
            <div class="bar"
                 id="progress-bar">
               1%
            </div>
         </div>
         <div class="labels mt-3">
            <div v-for="step in steps"
                 class="label">
               <transition name="fade">
                  <p v-if="step.active">
                     {{ step.label }}...
                  </p>
               </transition>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import Home from '@/views/Home'

export default {
   name:    'Processing',
   methods: {
      showLabel(index) {
         let labelElements = document.querySelectorAll('.label')

         this.steps[index].active = true

         for (const e of labelElements) {
            if (typeof e !== "undefined") {
               e.classList.add('d-none')
            }
         }

         if (typeof labelElements[index] !== "undefined") {
            labelElements[index].classList.remove('d-none')
         }
      },
      update() {
         this.show = false

         let labels = this.steps
         let element = document.getElementById("progress-bar")
         let width = 0
         let identity = setInterval(scene, 10 * labels.length)
         let _this = this

         function scene() {
            if (width >= 100) {
               clearInterval(identity)
            } else {
               width++

               element.style.width = width + '%'
               element.innerText = width + '%'

               if (width === 1) {
                  _this.showLabel(0)
               } else if (width === 20) {
                  _this.showLabel(1)
               } else if (width === 40) {
                  _this.showLabel(2)
               } else if (width === 60) {
                  _this.showLabel(3)
               } else if (width === 80) {
                  _this.showLabel(4)
               } else if (width === 100) {
                  _this.$router.push(
                     {
                        name:   'Results',
                        params: {
                           points: _this.$root.$data.points
                        }
                     }
                  )

                  clearInterval(identity)
               }
            }
         }
      }
   },
   mounted() {
      this.update()
   },
   data() {
      return {
         show:  true,
         steps: [
            {
               label:  'Ihre Anfrage wurde entgegen genommen',
               active: false
            },
            {
               label:  'Ihre Finanzstruktur wird geprüft',
               active: false
            },
            {
               label:  'Die Inflationsrate wird einbezogen',
               active: false
            },
            {
               label:  'Die Sofaritze wird noch mal durchwühlt',
               active: false
            },
            {
               label:  'Ihr Ergebnis wird berechnet',
               active: false
            }
         ]
      }
   },
   components: {
      Home
   }
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
   transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
   opacity: 0;
}

.bar-container {
   border:  2px solid #1d3f66;
   width:   100%;
   padding: 3px;
}

.bar {
   height:           30px;
   width:            5%;
   background-color: #1d3f66;
   line-height:      30px;
   color:            #ffffff;
   text-align:       center;
}

.labels {
   font-size: 32px;
   color:     #1d3f66;
}

.label {
   transition: ease .2s;
}
</style>