<template>
  <article :class="['item', 'text-center', 'p-3', { active }]"
           @click="updatePoints(item.points); active = !active">
    <div class="label pt-2 pb-3 py-lg-0 align-self-center">
      {{ item.label }}
    </div>
    <button class="button ripple"
            type="button">
      <span v-if="this.item.active">Abwählen</span>
      <span v-else>Auswählen</span>
    </button>
  </article>
</template>
<script>
export default {
  data() {
    return {
      active: false
    }
  },
  props:   {
    item: Object
  },
  methods: {
    updatePoints(points) {
      let newPoints = 0

      if (this.item.active) {
        newPoints = this.$root.$data.points - points
      } else {
        newPoints = this.$root.$data.points + points
      }

      this.item.active = !this.item.active

      this.$root.$data.points = newPoints
    }
  }
}
</script>
<style scoped>
.item {
  background-color: #e3ebf5;
  cursor:           pointer;
  height:           230px;
  transition:       ease-in-out .2s;
}

@media (max-width: 500px) {
  .item {
    height: auto;
  }
}

.item.active {
  background-color: #3674b6;
  color:            #ffffff;
}

.label {
  align-items: center;
  display:     grid;
  font-size:   26px;
  height:      70%;
}

.button {
  background-color: #dc6d31;
  border:           0;
  color:            #ffffff;
  font-size:        1.6rem;
  font-weight:      700;
  padding:          10px;
  text-transform:   uppercase;
  transition:       ease-in .2s;
  width:            100%;
}

.button:hover {
  background-color: #bb5d29;
}

.button.blue {
  background-color: #3674b6;
  color:            #ffffff;
}

@media (max-width: 768px) {
  .button {
    font-size: 1.1rem;
  }
}
</style>