<template>
  <div class="row">
    <div v-for="item in $root.$data.items"
         :key="item.id"
         class="col-12 col-md-4 col-lg-6 mb-3">
      <Item :item="item"></Item>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <router-link :to="{ name: 'Processing', params: { points: this.$root.points }}"
                   class="button w-50 w-50">
        Senden
        <img alt=">"
             src="../assets/images/right-arrow.svg"
             class="button-arrow">
      </router-link>
    </div>
  </div>
</template>
<script>
import Item from '@/components/Item.vue'

export default {
  components: {
    Item
  }
}
</script>
<style>
.button {
  background-color: #3674b6;
  border:           0;
  color:            #ffffff;
  display:          block;
  font-size:        1.6rem;
  font-weight:      700;
  margin:           0 auto;
  padding:          10px 20px;
  text-align:       center;
  text-decoration:  none;
  text-transform:   uppercase;
  transition:       ease-in .2s;
}

.button:hover {
  background-color: #295993;
  color:            #ffffff;
}

.button.orange {
  background-color: #ce7240;
}

.button.orange:hover {
  background-color: #b36337;
}

.button-arrow {
  position: relative;
  bottom:   1px;
}
</style>