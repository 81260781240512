<template>
  <div class="row">
    <Result v-if="points <= 8"
            color="red"></Result>
    <Result v-else-if="points >= 9 && points <= 15"
            color="yellow"></Result>
    <Result v-else-if="points >= 16"
            color="green"></Result>
  </div>
  <div class="row mt-3">
    <div class="col-12 col-sm-6 text-center">
         <a href="/"
            class="button w-100 mb-2 mb-md-0"
            title="Zurück">
           <img alt="<" class="button-arrow"
                src="../assets/images/right-arrow.svg" style="transform:rotateY(180deg)">
           Test wiederholen
         </a>
    </div>
    <div class="col-12 col-sm-6">
      <a class="button orange"
         href="https://www.vbohz.de/service/terminvereinbarung-online.html#parsys_terminvereinbarung"
         target="_blank"
         title="Jetzt Termin vereinbaren">
        <span class="d-none d-md-inline-block">Jetzt</span> Termin vereinbaren


            <img alt=">"
                 src="../assets/images/right-arrow.svg">
         </a>
    </div>
  </div>
</template>
<script>
import Result from '@/components/Result'

export default {
  data() {
    return {
      points: this.$route.params.points
    }
  },
  components: {
    Result
  }
}
</script>