<template>
  <div class="col-6 mx-auto mb-5 d-md-none">
    <Ampel :color="color"></Ampel>
  </div>
  <div class="col-3 mx-auto mb-5 mb-md-0 mx-md-0 d-none d-md-block">
    <Ampel :color="color"></Ampel>
  </div>
  <div class="col-auto">
  </div>
  <div class="col align-self-center"
       id="result-text">
    <div v-if="color === 'green'">
      <h2 class="mb-5 flash-green">SIE STEHEN GANZ GUT!</h2>
      <h4>Mit professioneller Beratung von uns können Sie Ihr Vermögen noch besser anlegen. Nehmen Sie einfach
          Kontakt zu uns auf.</h4>
    </div>
    <div v-if="color === 'yellow'">
      <h2 class="mb-5 flash-yellow">ACHTUNG!</h2>
      <h4>Sie laufen Gefahr, Ihr Vermögen falsch anzulegen. Bitte nehmen Sie Kontakt zu uns auf!</h4>
    </div>
    <div v-else-if="color === 'red'">
      <h2 class="mb-5 flash-red">ACHTUNG! SIE SIND FALSCHPARKER!</h2>
      <h4>Wir raten dringend dazu, sofort Kontakt zu uns aufzunehmen!</h4>
    </div>
  </div>
</template>
<script>
import Ampel from '@/components/Ampel'

export default {
  name:       "Result",
  props:      {
    color: String
  },
  components: {
    Ampel
  }
}
</script>
<style scoped>
h2 {
  color:       #1c3d64;
  font-size:   52px;
  font-weight: 700;
}

h4 {
  color:     #1c3d64;
  font-size: 36px;
}

@media (max-width: 992px) {
  #result-text {
    text-align: center;
  }
}


circle.pulsate {
  animation: blink 0.8s infinite;
}

.flash-green {
  animation: flash-green 0.8s infinite;
}

.flash-yellow {
  animation: flash-yellow 0.8s infinite;
}

.flash-red {
  animation: flash-red 0.8s infinite;
}

@keyframes flash-red {
  0% {
    color: red;
  }
  50% {
    color: #0f3f69;
  }
  100% {
    color: red;
  }
}

@keyframes flash-yellow {
  0% {
    color: #ffc800;
  }
  50% {
    color: #0f3f69;
  }
  100% {
    color: #ffc800;
  }
}

@keyframes flash-green {
  0% {
    color: #00b015;
  }
  50% {
    color: #0f3f69;
  }
  100% {
    color: #00b015;
  }
}
</style>