<template>
  <svg enable-background="new 0 0 71.4 119.1"
       viewBox="0 0 71.4 119.1"
       xmlns="http://www.w3.org/2000/svg">
    <g stroke="#0f3f69"
       stroke-miterlimit="10">
      <path d="m49.6 117.9h-27.2c-5 0-9-4.1-9-9v-98.2c0-5 4.1-9 9-9h27.1c5 0 9 4.1 9 9v98.2c.1 4.9-4 9-8.9 9z"
            fill="none"
            stroke-width="2.801"/>
      <g stroke-linecap="round"
         stroke-linejoin="round">
        <g fill="none">
          <path d="m58.6 14.7h7.2 4.5c0 5.5-1.6 13-11.7 13"
                stroke-width="2.801"/>
          <path d="m58.6 48.1h7.2 4.5c0 5.5-1.6 13-11.7 13"
                stroke-width="2.801"/>
          <path d="m58.6 81.5h7.2 4.5c0 5.5-1.6 13-11.7 13"
                stroke-width="2.801"/>
          <path d="m13.4 13.7h-7.2-4.5c0 5.5 1.6 13 11.7 13"
                stroke-width="2.801"/>
          <path d="m13.4 47.1h-7.2-4.5c0 5.5 1.6 13 11.7 13"
                stroke-width="2.801"/>
          <path d="m13.4 80.5h-7.2-4.5c0 5.5 1.6 13 11.7 13"
                stroke-width="2.801"/>
        </g>
        <g v-if="color === 'red'">a
          <circle cx="36"
                  cy="24.7"
                  fill="#e20917"
                  class="blink-red"
                  r="13.1"
                  stroke-width="2.3716"/>
          <path d="m19.9 19.8c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="none"
                stroke-width="2.6373"/>
          <path d="m19.9 54.6c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="none"
                stroke-width="2.6373"/>
          <path d="m19.9 89.4c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="none"
                stroke-width="2.6373"/>
          <circle cx="36"
                  cy="59.1"
                  fill="#fff"
                  r="13.1"
                  stroke-width="2.3716"/>
          <circle cx="36"
                  cy="93.5"
                  fill="#fff"
                  r="13.1"
                  stroke-width="2.3716"/>
        </g>
        <g v-else-if="color === 'yellow'">
          <circle cx="36"
                  cy="24.7"
                  fill="#fff"
                  r="13.1"
                  stroke-width="2.3716"/>
          <path d="m19.9 19.8c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="none"
                stroke-width="2.6373"/>
          <path d="m19.9 54.6c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="#fff"
                stroke-width="2.6373"/>
          <path d="m19.9 89.4c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="none"
                stroke-width="2.6373"/>
          <circle cx="36"
                  cy="59.1"
                  fill="#fdd000"
                  class="blink-yellow"
                  r="13.1"
                  stroke-width="2.3716"/>
          <circle cx="36"
                  cy="93.5"
                  fill="#fff"
                  r="13.1"
                  stroke-width="2.3716"/>
        </g>
        <g v-else-if="color === 'green'">
          <circle cx="36"
                  cy="24.7"
                  fill="#fff"
                  r="13.1"
                  stroke-width="2.3716"/>
          <path d="m19.9 19.8c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="none"
                stroke-width="2.6373"/>
          <path d="m19.9 54.6c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="none"
                stroke-width="2.6373"/>
          <path d="m19.9 89.4c0-7.2 7.2-13.1 16.1-13.1s16.1 5.8 16.1 13.1"
                fill="none"
                stroke-width="2.6373"/>
          <circle cx="36"
                  cy="59.1"
                  fill="#fff"
                  r="13.1"
                  stroke-width="2.3716"/>
          <circle cx="36"
                  cy="93.5"
                  fill="#e20c17"
                  r="13.1"
                  class="blink-green"
                  stroke-width="2.3716"/>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Ampel",
  created() {
    this.$route.params.points = '0'
    this.points               = 0
  },
  computed: {
    fill() {
      let color = '#e20917'

      if (this.color === 'yellow') {
        color = '#ff00ff'
      }

      return color
    }
  },
  props:    {
    color: String
  }
}
</script>
<style scoped>
@keyframes blink-green {
  100%,
  0% {
    fill: #46ab36;
  }
  50% {
    fill: transparent;
  }
}

@keyframes blink-yellow {
  100%,
  0% {
    fill: #fdd000;
  }
  50% {
    fill: transparent;
  }
}

@keyframes blink-red {
  100%,
  0% {
    fill: #ff0000;
  }
  50% {
    fill: transparent;
  }
}

.blink-green {
  animation: blink-green 0.8s infinite;
}

.blink-yellow {
  animation: blink-yellow 0.8s infinite;
}

.blink-red {
  animation: blink-red 0.8s infinite;
}
</style>