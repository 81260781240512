import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/Home'
import Results from '@/views/Results'
import Processing from '@/views/Processing'

const pointsRegex = ':points([0-9]{1,2})/'

const routes = [
	{
		path:      '/',
		name:      'Home',
		component: Home
	},
	{
		path:      '/processing/' + pointsRegex,
		name:      'Processing',
		component: Processing,
		props:     true
	},
	{
		path:      '/results/' + pointsRegex,
		name:      'Results',
		component: Results,
		props:     true
	},
	{
		path:     '/:catchAll(.*)',
		name:     '404',
		redirect: '/'
	}
]

const router = createRouter(
	{
		history: createWebHistory(process.env.BASE_URL),
		routes
	}
)

export default router